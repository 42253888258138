import React, { createContext, useContext, useState, useEffect } from 'react';
import client from './utils/ShopifyClient';

const StoreContext = createContext();

const localStorageKey = 'shopify_cart_id';

export const StoreProvider = ({ children }) => {
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(false);


  // --- Initialisation du panier ---
  useEffect(() => {
    const existingCartId = localStorage.getItem(localStorageKey);
    if (existingCartId) {
      fetchCart(existingCartId);
    } else {
      createCart();
    }
  }, []);

  const fetchCart = async (cartId) => {
    const query = `
      query ($id: ID!) {
        cart(id: $id) {
          id
          checkoutUrl
          lines(first: 10) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      src
                    }
                    price {
                      amount
                      currencyCode
                    }
                    product {
                      title
                    }
                  }
                }
              }
            }
          }
          cost {
            subtotalAmount {
              amount
              currencyCode
            }
          }
        }
      }
    `;
    const { cart } = await client.request(query, { id: cartId });
    setCart(cart);
  };

  const createCart = async () => {
    const mutation = `
      mutation {
        cartCreate {
          cart {
            id
            checkoutUrl
          }
        }
      }
    `;
    const { cartCreate } = await client.request(mutation);
    const newCart = cartCreate.cart;
    localStorage.setItem(localStorageKey, newCart.id);
    setCart(newCart);
  };

  const addVariantToCart = async (variantId, quantity) => {
    setLoading(true);

    let currentCartId = cart?.id;

    // Si le checkoutUrl est absent (panier invalidé), on recrée le panier
    if (!cart?.checkoutUrl) {
      const createCartMutation = `
      mutation {
        cartCreate {
          cart {
            id
            checkoutUrl
          }
        }
      }
    `;

      const { cartCreate } = await client.request(createCartMutation);
      currentCartId = cartCreate.cart.id;
      setCart(cartCreate.cart);
      localStorage.setItem('shopify_cart_id', currentCartId);
    }

    const mutation = `
      mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                  merchandise {
                    ... on ProductVariant {
                      id
                      title
                      image {
                        src
                      }
                      price {
                        amount
                        currencyCode
                      }
                      product {
                        title
                      }
                      
                    }
                  }
                }
              }
            }
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `;

    const variables = {
      cartId: cart.id,
      lines: [{ merchandiseId: variantId, quantity }],
    };

    const { cartLinesAdd } = await client.request(mutation, variables);
    setCart(cartLinesAdd.cart);
    setLoading(false);
  };

  const removeLineItem = async (lineId) => {
    setLoading(true);
    const mutation = `
      mutation ($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            lines(first: 10) {
              edges {
                node {
                  id
                  quantity
                }
              }
            }
            cost {
              subtotalAmount {
                amount
                currencyCode
              }
            }
          }
        }
      }
    `;

    const variables = { cartId: cart.id, lineIds: [lineId] };
    const { cartLinesRemove } = await client.request(mutation, variables);
    setCart(cartLinesRemove.cart);
    setLoading(false);
  };


  return (
    <StoreContext.Provider value={{ cart, addVariantToCart, removeLineItem, createCart, loading }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  return useContext(StoreContext);
};
