import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(`https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2023-10/graphql.json`, {
  headers: {
    'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
    'Content-Type': 'application/json',
  },
});

export default client;
